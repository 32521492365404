import './styles/global.scss'

import {
  Routes,
  Route,
} from "react-router-dom";

import {AnimatePresence} from "framer-motion"


import Home from './components/Home';
import About from './components/About'
import Work from './components/Work'
import Contact from './components/Contact'

function App() {
  return (
    <div className="App">
      <div className='background'>

      </div>
      <AnimatePresence exitBeforeEnter> 
        <Routes>
          <Route path="about" element={<About />}/>
          <Route path="contact" element={<Contact />}/>
          <Route path="work" element={<Work />}/>
          <Route path="/" exact element={<Home />}/>
        </Routes>
      </AnimatePresence>
      
    </div>
    
  );
}

export default App;
