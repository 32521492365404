import React from 'react';
import Logo from '../../assets/logo.svg';
import Button from '../partials/Button'

import { motion } from 'framer-motion';

import { variants, transition } from '../utils/transitions'

import './index.scss';

function Home() {

    const links = [
        {
            label: "What We Do",
            link: "work"
        },
        {
            label: "The Team",
            link: "about"
        },
        {
            label: "Contact Us",
            link: "contact"
        }
    ]

  return (
    <motion.div className='homePage' initial="out" animate="in" exit="out" variants={variants} transition={transition}>
        <div className='homePage-logo'>
            <img src={Logo} alt="img-logo"/> 
        </div>
        <div className='homePage-welcome'>
            <h1></h1>
            <p>At our AI media company, we believe in crafting brands that resonate with the pulse of reality, sparking transformative shifts. Our approach revolves around experience-driven brand strategy and identity conception, guaranteeing that every creation is meticulously tailored to the realm of tangible products and services.</p>
        </div>
        <div className='homePage-links'>
            {
                links.map((link, i) => <Button {...link} key={i} />)
            }
        </div>
    </motion.div>
  )
}

export default Home