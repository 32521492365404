import React from 'react'
import { Link } from 'react-router-dom'

import './index.scss';

function Button({link, label, sm}) {
  return (
    <Link className={sm ? "linkButton sm" : "linkButton"} to={link}>{label}</Link>
  )
}

export default Button