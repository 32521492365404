import React from 'react'
import WorkImg from '../../assets/work-img.jpg'
import Button from '../partials/Button'
import { motion } from 'framer-motion'
import { variants, transition } from '../utils/transitions'

import './index.scss'

function Work() {

  return (
    <motion.div className='workPage' initial="out" animate="in" exit="out" variants={variants} transition={transition}>
        <div className='workPage-links'>
          <Button label="Back" link="/" sm/>
        </div>
        <div className='workPage-content'>
          <h2>What we do</h2>
          <img src={WorkImg} alt="work-img"/>
          <p>In the dynamic realm of AI and media, our proficient team seamlessly intertwines the art of storytelling, technological prowess, and experience design, culminating in impactful brand narratives. Fueled by our profound knowledge in AI, we construct custom data ecosystems, synergizing with our tech virtuosos, empowering enterprises to forge deeper connections with users through the metamorphosis of concepts into functional realities. This orchestration of optimized experiences paves the way for precision-targeted content dissemination across the expansive digital landscape</p>
        </div>
    </motion.div>
  )
}

export default Work