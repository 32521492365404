import React from 'react'
import Button from '../partials/Button'
import { motion } from 'framer-motion'

import {FaTelegramPlane, FaTwitter, FaLinkedinIn} from 'react-icons/fa'
import { variants, transition } from '../utils/transitions'

import './index.scss'

function Contact() {

  const contact = [
    
    {
      icon: <FaTelegramPlane />,
      link: 'https://web.telegram.org/'
    },
    {
      icon: <FaTwitter />,
      link: 'https://twitter.com'
    },
    {
      icon: <FaLinkedinIn />,
      link: 'https://linkedin.com'
    },
  ]

  

  return (
    <motion.div className='contactPage' initial="out" animate="in" exit="out" variants={variants} transition={transition}>
        <div className='contactPage-links'>
          <Button label="Back" link="/" sm/>
        </div>
        <div className='contactPage-content'>
          <h2>Contact Us</h2>

          <p>
          No matter what your ask—big or small—we’re ready to talk. We’re all about working together to solve your gnarliest, most twisted, seemingly impossible challenges. Trust us—we’ve seen it all. (And solved most of it.)
          </p>

          <a href='information@untitled.tld' className='email'>hello@studioseven.ai</a>

          <ul>
            {contact.map(({icon, link}, i) => 
              <li key={i}><a href={link}>{icon}</a></li>
            )}
          </ul>

        </div>
    </motion.div>
  )
}

export default Contact