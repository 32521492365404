import React from 'react'
import Button from '../partials/Button'
import { motion } from 'framer-motion'

import { variants, transition } from '../utils/transitions'
import './index.scss'

function About() {

  const team = [
    {
      name: "member1",
      link: "https://a7b193a90e6697d1.demo.carrd.co/assets/images/gallery01/1acb9855.jpg?v=d55711e0"
    },
    {
      name: "member2",
      link: "https://a7b193a90e6697d1.demo.carrd.co/assets/images/gallery01/1512dd53.jpg?v=d55711e0"
    },
    {
      name: "member3",
      link: "https://a7b193a90e6697d1.demo.carrd.co/assets/images/gallery01/51c13fba.jpg?v=d55711e0"
    },
    {
      name: "member4",
      link: "https://a7b193a90e6697d1.demo.carrd.co/assets/images/gallery01/e78a056e.jpg?v=d55711e0"
    }
  ]


  return (
    <motion.div className='aboutPage' initial="out" animate="in" exit="out" variants={variants} transition={transition}>
        <div className='aboutPage-links'>
          <Button label="Back" link="/" sm/>
        </div>
        <div className='aboutPage-content'>
          <h2>The Team</h2>
          <div className='aboutPage-content_team'>
            {team.map(({name, link}, i) => <img src={link} alt={name} key={i}/>)}
          </div>
          <p>Guided by a steadfast devotion to diversity, equity, and inclusion, our team's very essence is shaped. We firmly believe that a sense of belonging and a thriving community arise from a genuine care for one another. Our pinnacle of excellence is achieved when every individual is actively involved, uniting efforts to cultivate a workplace that champions diversity and inclusivity at its core</p>
        </div>
    </motion.div>
  )
}

export default About